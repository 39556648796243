import { DateTime } from "luxon";
import { defineStore } from "pinia";

import { useFiltersStore } from "@/store/filters";
import type { requestParams } from "@/types/requests.ts";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers.ts";
import {
  getReceivingKpiChartApi,
  getReceivingKpisApi,
  getReceivingKpiTotalApi,
  getReceivingSecondChartApi,
  getReceivingTimeTableByBrandApi,
  getReceivingTimeTableByProductApi,
} from "@/api/warehouse.ts";

import { IKpisFormat } from "@/types/Kpi.ts";
import type { TableRow } from "@/types/table.ts";
import { IReceivingStore } from "@/types/warehouse/receiving.ts";

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const useReceivingStore = defineStore("receivingStore", {
  state: (): IReceivingStore => ({
    KPITotal: null,
    WoWKpiData: null,
    MoMKpiData: null,
    YoYKpiData: null,
    PoPKpiData: null,
    KPIChart: null,
    secondChart: null,
    receiveTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    isReceiveKpiLoader: true,
    isReceiveChartLoader: true,
    isReceiveTableLoader: true,
    isReceiveTableTotalLoader: true,
    isReceiveSecondChartLoader: true,
  }),
  actions: {
    abortControllersStore() {
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortTableRequest() {
      tableController = replaceSingleController(tableController);
    },

    updateKpisLoader(value: boolean) {
      this.isReceiveKpiLoader = value;
    },

    async getReceivingKpi(
      params: requestParams,
      type: "WoWKpiData" | "MoMKpiData" | "YoYKpiData" | "PoPKpiData",
    ) {
      try {
        const { data } = await getReceivingKpisApi(params, {
          signal: getAbortSignal(controllers),
        });
        this[type] = null;
        this[type] = data;
      } catch (e) {
        console.log(e);
      }
    },

    async getReceivingTotalKpi(params: requestParams) {
      try {
        const { data } = await getReceivingKpiTotalApi(params, {
          signal: getAbortSignal(controllers),
        });
        this.KPITotal = data;
        // eslint-disable-next-line no-empty
      } finally {
      }
    },

    async getReceivingKpiChart(params: requestParams) {
      const filterStore = useFiltersStore();
      this.isReceiveChartLoader = true;
      try {
        const { data } = await getReceivingKpiChartApi(params, {
          signal: getAbortSignal(controllers),
        });

        this.KPIChart = data.sort((a, b) => {
          if (filterStore.granularity === "MONTH") {
            return (
              DateTime.fromFormat(a.DATE, "yyyy-dd-MM").toMillis() -
              DateTime.fromFormat(b.DATE, "yyyy-dd-MM").toMillis()
            );
          } else {
            return (
              DateTime.fromISO(a.DATE).toMillis() -
              DateTime.fromISO(b.DATE).toMillis()
            );
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.isReceiveChartLoader = false;
      }
    },
    async getReceivingSecondChart(params: requestParams) {
      const filterStore = useFiltersStore();
      this.isReceiveSecondChartLoader = true;
      try {
        const { data } = await getReceivingSecondChartApi(params, {
          signal: getAbortSignal(controllers),
        });

        this.secondChart = data.sort((a, b) => {
          if (filterStore.granularity === "MONTH") {
            return (
              DateTime.fromFormat(a.DATE, "yyyy-dd-MM").toMillis() -
              DateTime.fromFormat(b.DATE, "yyyy-dd-MM").toMillis()
            );
          } else {
            return (
              DateTime.fromISO(a.DATE).toMillis() -
              DateTime.fromISO(b.DATE).toMillis()
            );
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.isReceiveSecondChartLoader = false;
      }
    },

    async getReceivingTimeTable(
      params: requestParams,
      callback?: (data: TableRow[]) => void,
      byProduct?: boolean,
    ) {
      if (params.limit === 0) {
        this.isReceiveTableTotalLoader = true;
      } else {
        this.isReceiveTableLoader = true;
      }

      try {
        if (byProduct) {
          const { data } = await getReceivingTimeTableByProductApi(params, {
            signal: tableController.signal,
          });

          if (params.limit === 0) {
            callback && callback(data.data);
          } else {
            this.receiveTable.data = data.data;
            this.receiveTable.pagination = data.pagination;
          }
        } else {
          const { data } = await getReceivingTimeTableByBrandApi(params, {
            signal: tableController.signal,
          });

          if (params.limit === 0) {
            callback && callback(data.data);
          } else {
            this.receiveTable.data = data.data;
            this.receiveTable.pagination = data.pagination;
          }
        }
        this.isReceiveTableLoader = false;
        this.isReceiveTableTotalLoader = false;
      } catch (err: any) {
        const errorData = JSON.parse(err?.message);
        this.isReceiveTableLoader = false;
        this.isReceiveTableTotalLoader = false;
        if (errorData.status === "canceled") {
          this.isReceiveTableLoader = true;
        }
      }
    },
  },
  getters: {
    totalUnitsKpi(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.PoPKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.WoWKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.MoMKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.YoYKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Total Units",
        values: values,
      };
    },
    avgMinPerUnitKpi(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`AVG_MIN_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`AVG_MIN_PER_UNIT_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`AVG_MIN_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`AVG_MIN_PER_UNIT_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`AVG_MIN_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`AVG_MIN_PER_UNIT_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`AVG_MIN_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`AVG_MIN_PER_UNIT_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Total Shipments",
        values: values,
      };
    },
    totalPackQuantityKpi(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference:
              this.PoPKpiData?.[`TOTAL_CASE_PACK_QUANTITY_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`TOTAL_CASE_PACK_QUANTITY_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference:
              this.WoWKpiData?.[`TOTAL_CASE_PACK_QUANTITY_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`TOTAL_CASE_PACK_QUANTITY_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference:
              this.MoMKpiData?.[`TOTAL_CASE_PACK_QUANTITY_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`TOTAL_CASE_PACK_QUANTITY_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference:
              this.YoYKpiData?.[`TOTAL_CASE_PACK_QUANTITY_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`TOTAL_CASE_PACK_QUANTITY_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Units Per Shipment",
        values: values,
      };
    },
    avgPackQuantity(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`AVG_PACK_QUANTITY_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`AVG_PACK_QUANTITY_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`AVG_PACK_QUANTITY_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`AVG_PACK_QUANTITY_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`AVG_PACK_QUANTITY_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`AVG_PACK_QUANTITY_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`AVG_PACK_QUANTITY_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`AVG_PACK_QUANTITY_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "ltl Percentage",
        values: values,
      };
    },
    totalPallets(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`TOTAL_PALLETS_DIFF`] || "",
            ratio_percentage: this.PoPKpiData?.[`TOTAL_PALLETS_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`TOTAL_PALLETS_DIFF`] || "",
            ratio_percentage: this.WoWKpiData?.[`TOTAL_PALLETS_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`TOTAL_PALLETS_DIFF`] || "",
            ratio_percentage: this.MoMKpiData?.[`TOTAL_PALLETS_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`TOTAL_PALLETS_DIFF`] || "",
            ratio_percentage: this.YoYKpiData?.[`TOTAL_PALLETS_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Parcel Percentage",
        values: values,
      };
    },
    avgMinPerPallet(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`AVG_MIN_PER_PALLET_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`AVG_MIN_PER_PALLET_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`AVG_MIN_PER_PALLET_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`AVG_MIN_PER_PALLET_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`AVG_MIN_PER_PALLET_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`AVG_MIN_PER_PALLET_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`AVG_MIN_PER_PALLET_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`AVG_MIN_PER_PALLET_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Parcel Percentage",
        values: values,
      };
    },
    totalPOs(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`TOTAL_POS_DIFF`] || "",
            ratio_percentage: this.PoPKpiData?.[`TOTAL_POS_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`TOTAL_POS_DIFF`] || "",
            ratio_percentage: this.WoWKpiData?.[`TOTAL_POS_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`TOTAL_POS_DIFF`] || "",
            ratio_percentage: this.MoMKpiData?.[`TOTAL_POS_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`TOTAL_POS_DIFF`] || "",
            ratio_percentage: this.YoYKpiData?.[`TOTAL_POS_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Parcel Percentage",
        values: values,
      };
    },
    avgUnitsPerPO(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`AVG_UNITS_PER_PO_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`AVG_UNITS_PER_PO_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`AVG_UNITS_PER_PO_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`AVG_UNITS_PER_PO_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`AVG_UNITS_PER_PO_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`AVG_UNITS_PER_PO_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`AVG_UNITS_PER_PO_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`AVG_UNITS_PER_PO_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Parcel Percentage",
        values: values,
      };
    },
  },
});
