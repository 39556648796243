import { DateTime } from "luxon";
import { defineStore } from "pinia";

import { useFiltersStore } from "@/store/filters";
import type { requestParams } from "@/types/requests.ts";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers.ts";
import {
  getOutboundChartApi,
  getOutboundKpisApi,
  getOutboundKpiTotalApi,
  getOutboundTableApi,
} from "@/api/warehouse.ts";
import { IKpisFormat } from "@/types/Kpi.ts";
import type { TableRow } from "@/types/table.ts";
import { IWarehouseOutboundStore } from "@/types/warehouse";

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const useWarehouseStore = defineStore("warehouseStore", {
  state: (): IWarehouseOutboundStore => ({
    KPITotal: null,
    WoWKpiData: null,
    MoMKpiData: null,
    YoYKpiData: null,
    PoPKpiData: null,
    outboundChart: null,
    outboundTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    isOutboundKpiLoader: true,
    isOutboundChartLoader: true,
    isOutboundTableLoader: true,
    isOutboundTableTotalLoader: true,
  }),
  actions: {
    abortControllersStore() {
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortTableRequest() {
      tableController = replaceSingleController(tableController);
    },

    updateKpisLoader(value: boolean) {
      this.isOutboundKpiLoader = value;
    },

    async getWarehouseKpi(
      params: requestParams,
      type: "WoWKpiData" | "MoMKpiData" | "YoYKpiData" | "PoPKpiData",
    ) {
      try {
        const { data } = await getOutboundKpisApi(params, {
          signal: getAbortSignal(controllers),
        });
        this[type] = null;
        this[type] = data;
      } catch (e) {
        console.log(e);
      }
    },

    async getWarehouseTotalKpi(params: requestParams) {
      try {
        const { data } = await getOutboundKpiTotalApi(params, {
          signal: getAbortSignal(controllers),
        });
        this.KPITotal = data;
        // eslint-disable-next-line no-empty
      } finally {
      }
    },

    async getOutboundChart(params: requestParams) {
      const filterStore = useFiltersStore();
      this.isOutboundChartLoader = true;
      try {
        const { data } = await getOutboundChartApi(params, {
          signal: getAbortSignal(controllers),
        });

        this.outboundChart = data.sort((a, b) => {
          if (filterStore.granularity === "MONTH") {
            return (
              DateTime.fromFormat(a.DATE, "yyyy-dd-MM").toMillis() -
              DateTime.fromFormat(b.DATE, "yyyy-dd-MM").toMillis()
            );
          } else {
            return (
              DateTime.fromISO(a.DATE).toMillis() -
              DateTime.fromISO(b.DATE).toMillis()
            );
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.isOutboundChartLoader = false;
      }
    },

    async getOutboundTable(
      params: requestParams,
      callback?: (data: TableRow[]) => void,
    ) {
      if (params.limit === 0) {
        this.isOutboundTableTotalLoader = true;
      } else {
        this.isOutboundTableLoader = true;
      }

      try {
        const { data } = await getOutboundTableApi(params, {
          signal: tableController.signal,
        });

        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.outboundTable.data = data.data;
          this.outboundTable.pagination = data.pagination;
        }
        this.isOutboundTableLoader = false;
        this.isOutboundTableTotalLoader = false;
      } catch (err: any) {
        const errorData = JSON.parse(err?.message);
        this.isOutboundTableLoader = false;
        this.isOutboundTableTotalLoader = false;
        if (errorData.status === "canceled") {
          this.isOutboundTableLoader = true;
        }
      }
    },
  },
  getters: {
    totalShipmentKpi(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`TOTAL_SHIPMENTS_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`TOTAL_SHIPMENTS_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`TOTAL_SHIPMENTS_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`TOTAL_SHIPMENTS_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`TOTAL_SHIPMENTS_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`TOTAL_SHIPMENTS_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`TOTAL_SHIPMENTS_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`TOTAL_SHIPMENTS_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Total Shipments",
        values: values,
      };
    },
    totalUnitsKpi(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.PoPKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.WoWKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.MoMKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.YoYKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Total Units",
        values: values,
      };
    },
    unitsPerShipmentKpi(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`UNITS_PER_SHIPMENT_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`UNITS_PER_SHIPMENT_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`UNITS_PER_SHIPMENT_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`UNITS_PER_SHIPMENT_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`UNITS_PER_SHIPMENT_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`UNITS_PER_SHIPMENT_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`UNITS_PER_SHIPMENT_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`UNITS_PER_SHIPMENT_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Units Per Shipment",
        values: values,
      };
    },
    ltlPercentageKpi(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`LTL_PERCENTAGE_DIFF`] || "",
            ratio_percentage: this.PoPKpiData?.[`LTL_PERCENTAGE_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`LTL_PERCENTAGE_DIFF`] || "",
            ratio_percentage: this.WoWKpiData?.[`LTL_PERCENTAGE_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`LTL_PERCENTAGE_DIFF`] || "",
            ratio_percentage: this.MoMKpiData?.[`LTL_PERCENTAGE_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`LTL_PERCENTAGE_DIFF`] || "",
            ratio_percentage: this.YoYKpiData?.[`LTL_PERCENTAGE_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "ltl Percentage",
        values: values,
      };
    },
    spPercentage(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`PARCEL_PERCENTAGE_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`PARCEL_PERCENTAGE_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`PARCEL_PERCENTAGE_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`PARCEL_PERCENTAGE_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`PARCEL_PERCENTAGE_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`PARCEL_PERCENTAGE_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`PARCEL_PERCENTAGE_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`PARCEL_PERCENTAGE_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Parcel Percentage",
        values: values,
      };
    },
    avgLtlCostPerUnit(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`AVG_LTL_COST_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`AVG_LTL_COST_PER_UNIT_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`AVG_LTL_COST_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`AVG_LTL_COST_PER_UNIT_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`AVG_LTL_COST_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`AVG_LTL_COST_PER_UNIT_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`AVG_LTL_COST_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`AVG_LTL_COST_PER_UNIT_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Parcel Percentage",
        values: values,
      };
    },
    avgSpCostPerUnit(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`AVG_SP_COST_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`AVG_SP_COST_PER_UNIT_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`AVG_SP_COST_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`AVG_SP_COST_PER_UNIT_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`AVG_SP_COST_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`AVG_SP_COST_PER_UNIT_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`AVG_SP_COST_PER_UNIT_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`AVG_SP_COST_PER_UNIT_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Parcel Percentage",
        values: values,
      };
    },
  },
});
