import { defineStore } from "pinia";

import { useReviewsStore } from "./reviews";
import { useInventoryStore } from "./inventory";
import { useProductAlertsStore } from "./product-alerts";
import { useProductReturnsStore } from "./product-returns";
import { useSalesAndTrafficStore } from "./sales-and-traffic";
import { useFunnelLevelStore } from "./ads-summary/funnelLevel";
import { useProductPerformanceStore } from "./product-performance";
import { useAdsSummaryAwarenessStore } from "./ads-summary/awareness";
import { useCampaignDetailsStore } from "./ads-summary/campaignDetails";
import { useAdsSummaryPerformanceStore } from "./ads-summary/performanse";
import { useNewBrandProtectionStore } from "@/store/brand-protection/main";
import { useAdsSummaryEfficienciesStore } from "./ads-summary/efficiencies";
import { usePerformanceSummaryStore } from "./ads-summary/performanceSummary";
import { useAccountHealthStore } from "@/store/account-health";
import { useAccountHealthMessagesStore } from "@/store/account-health/product-message.ts";
import { useWarehouseStore } from "@/store/warehouse";
import { useReceivingStore } from "@/store/warehouse/receiving.ts";
import { usePreppingStore } from "@/store/warehouse/prepping.ts";
import { useCycleTimeStore } from "@/store/warehouse/cycleTime.ts";

export const useAbortController = defineStore("abortController", {
  state: () => ({}),
  actions: {
    abortAllRequests() {
      const reviewStore = useReviewsStore();
      const inventoryStore = useInventoryStore();
      const alertStore = useProductAlertsStore();
      const accountStore = useAccountHealthStore();
      const funnelLevelStore = useFunnelLevelStore();
      const brandStore = useNewBrandProtectionStore();
      const productReturnsStore = useProductReturnsStore();
      const awarenessStore = useAdsSummaryAwarenessStore();
      const campaignDetailsStore = useCampaignDetailsStore();
      const salesAndTrafficStore = useSalesAndTrafficStore();
      const performanceStore = useAdsSummaryPerformanceStore();
      const efficienciesStore = useAdsSummaryEfficienciesStore();
      const accountMessageStore = useAccountHealthMessagesStore();
      const productPerformanceStore = useProductPerformanceStore();
      const performanceSummaryStore = usePerformanceSummaryStore();

      const warehouseCycleStore = useCycleTimeStore();
      const warehousePreppingStore = usePreppingStore();
      const warehouseOutboundStore = useWarehouseStore();
      const warehouseReceivingStore = useReceivingStore();

      // All requests
      brandStore.abortRequestsBrandStore();
      reviewStore.abortRequestsReviewStore();
      warehouseCycleStore.abortControllersStore();
      inventoryStore.abortRequestsInventoryStore();
      awarenessStore.abortRequestsAwarenessStore();
      warehousePreppingStore.abortControllersStore();
      warehouseOutboundStore.abortControllersStore();
      warehouseReceivingStore.abortControllersStore();
      performanceStore.abortRequestsPerformanceStore();
      funnelLevelStore.abortRequestsFunnelLevelStore();
      efficienciesStore.abortRequestsEfficienciesStore();
      accountStore.abortAccountHealthsActivityRequests();
      productReturnsStore.abortRequestsProductReturnsStore();
      salesAndTrafficStore.abortRequestsSalesAndTrafficStore();
      accountMessageStore.abortRequestsAccountHealthMessagesStore();
      performanceSummaryStore.abortRequestsPerformanceSummaryStore();

      // All table requests
      alertStore.abortNoFNSKUTable();
      reviewStore.abortTableRequest();
      alertStore.abortAmazonFBATable();
      alertStore.abortNoShelfLifeTable();
      awarenessStore.abortTableRequest();
      alertStore.abortNoMSRPorCOGSTable();
      alertStore.abortNotInNetsuiteTable();
      performanceStore.abortTableRequest();
      funnelLevelStore.abortTableRequest();
      efficienciesStore.abortTableRequest();
      reviewStore.abortTableRatingRequest();
      accountMessageStore.abortErrorTable();
      warehouseCycleStore.abortTableRequest();
      productPerformanceStore.abortLowTable();
      productReturnsStore.abortTableRequest();
      accountMessageStore.abortResolvedTable();
      productPerformanceStore.abortHighTable();
      alertStore.abortNoPrepDescriptionsTable();
      alertStore.abortTransparencyEnabledTable();
      warehousePreppingStore.abortTableRequest();
      warehouseOutboundStore.abortTableRequest();
      warehouseReceivingStore.abortTableRequest();
      inventoryStore.abortInventoryTableRequest();
      performanceSummaryStore.abortTableRequest();
      campaignDetailsStore.abortTableTypeRequest();
      salesAndTrafficStore.abortSalesTableRequest();
      productReturnsStore.abortReasonTableRequest();
      campaignDetailsStore.abortTableTargetRequest();
      salesAndTrafficStore.abortTopProductsRequest();
      accountStore.abortAccountHealthActivityTable();
      campaignDetailsStore.abortTableCategoriesRequest();
    },
  },
});
