import axios from "@/utils/axios";

import type {
  KpisTotal,
  Chart,
  KpiValue,
  MessagesResolvedTable,
} from "@/types/accountHealth/messages";
import type {
  KpiValue as ActivityKpi,
  Chart as ActivityChart,
  ActivityTable,
} from "@/types/accountHealth/activity";

import type { requestParams, PaginatedWrapper } from "@/types/requests";

export const getAccountHealthMessagesKpiApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<KpiValue>(
    "/account-health/product-messages/kpi",
    params,
    options,
  );
};

export const getAccountHealthMessagesKpiTotalApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<KpisTotal>(
    "/account-health/product-messages/kpi/totals",
    params,
    options,
  );
};

export const getAccountHealthMessagesChartApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<Chart[]>(
    "/account-health/product-messages/chart",
    params,
    options,
  );
};

export const getAccountHealthMessagesTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<MessagesResolvedTable[]>>(
    "/account-health/product-messages/table-product-messages",
    params,
    options,
  );
};

export const getAccountHealthActivityKpiApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ActivityKpi>(
    "/account-health/asin-activity/kpi",
    params,
    options,
  );
};

export const getAccountHealthActivityTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<ActivityTable[]>>(
    "/account-health/asin-activity/table-asin-activity",
    params,
    options,
  );
};

export const getAccountHealthActivityChartApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ActivityChart[]>(
    "/account-health/asin-activity/chart",
    params,
    options,
  );
};

export const getAccountHealthErrorsChartApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<
    {
      DATE: string;
      ERROR_MESSAGES: number;
      WARNING_MESSAGES: number;
    }[]
  >("/account-health/product-messages/chart-errors", params, options);
};
