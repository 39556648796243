import { defineStore } from 'pinia';

import type { AccountHealthActivityStore } from '@/types/accountHealth/activity';
import type { requestParams } from '@/types/requests';
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from '@/utils/createAbortControllers';
import {
  getAccountHealthActivityKpiApi,
  getAccountHealthActivityChartApi,
  getAccountHealthActivityTableApi,
} from '@/api/account-health';
import type { TableRow } from '@/types/table';

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const useAccountHealthStore = defineStore('accountHealth', {
  state: (): AccountHealthActivityStore => ({
    asinActivityKpis: null,
    asinActivityChart: [],
    activityTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    isAccHealthKpiLoader: true,
    isAccHealthChartLoader: true,
    isAccHealthTableLoader: true,
    isAccHealthTableTotalsLoader: true,
  }),

  actions: {
    abortAccountHealthsActivityRequests() {
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortAccountHealthActivityTable() {
      tableController = replaceSingleController(tableController);
    },

    async getAccountHealthActivityKpi(params: requestParams) {
      this.isAccHealthKpiLoader = true;
      try {
        const { data } = await getAccountHealthActivityKpiApi(params, {
          signal: getAbortSignal(controllers),
        });

        this.asinActivityKpis = data;
      } catch (e) {
        /* empty */
      } finally {
        this.isAccHealthKpiLoader = false;
      }
    },

    async getAccountHealthActivityChart(params: requestParams) {
      this.isAccHealthChartLoader = true;
      try {
        const { data } = await getAccountHealthActivityChartApi(params, {
          signal: getAbortSignal(controllers),
        });
        this.asinActivityChart = data;
      } catch {
        /* empty */
      } finally {
        this.isAccHealthChartLoader = false;
      }
    },

    async getAccountHealthActivityTable(
      params: requestParams,
      callback?: (data: TableRow[]) => void,
    ) {
      if (params.limit === 0) {
        this.isAccHealthTableTotalsLoader = true;
      } else {
        this.isAccHealthTableLoader = true;
      }
      try {
        const { data } = await getAccountHealthActivityTableApi(params, {
          signal: tableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.activityTable.data = data.data;
          this.activityTable.pagination = data.pagination;
        }
        this.isAccHealthTableLoader = false;
        this.isAccHealthTableTotalsLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isAccHealthTableLoader = false;
        this.isAccHealthTableTotalsLoader = false;
        if (errorData.status === 'canceled') {
          this.isAccHealthTableLoader = true;
        }
      }
    },
  },
});
