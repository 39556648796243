<script setup lang="ts">
import { DateTime } from "luxon";
import { debounce } from "lodash";
import { useRoute, useRouter } from "vue-router";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";

import { useStore } from "@/store/main";
import { useToast } from "@/composables/useToast";
import type { ProductGroup } from "@/store/main";
import { useFiltersStore } from "@/store/filters";
import { parseDateUtils } from "@/utils/parseDate";
import type {
  SelectedSearchingItem,
  PresetRange,
  Period,
  UpdateByProduct,
} from "@/types/adsSummary/index";

import SavingFilters from "./SavingFilters.vue";
import BaseSwitch from "@/components/common/CommonSwitch.vue";
import CommonButton from "@/components/common/CommonButton.vue";
import BaseSkeleton from "@/components/common/CommonSkeleton.vue";
import BaseSimpleSearch from "@/components/common/CommonSearch.vue";
import CommonDatePicker from "@/components/common/CommonDatePicker.vue";
import BrandsDropDown from "@/components/core/Filters/BrandsDropDown.vue";
import CommonMultiSelect from "@/components/common/CommonMultiSelect.vue";
import CommonGranularity from "@/components/common/CommonGranularity.vue";
import ChannelsDropDown from "@/components/core/Filters/ChannelsDropDown.vue";

const props = defineProps<{
  isFixed: boolean;
}>();

const route = useRoute();
const store = useStore();
const router = useRouter();
const filterStore = useFiltersStore();

const selectedProduct = ref<UpdateByProduct | null>(null);
const isLoading = ref<boolean>(false);
const productSearch = ref<number[]>([]);
const formattedDatesArray = ref<string[]>([]);
const selectedBrand = ref<string | string[]>();
const blockUpdateGranylarity = ref<boolean>(false);
const selectedChannel = ref<string[] | number[]>();
const productStatus = ref({ name: "All", value: "" });
const amzStatus = ref({ name: "All", value: "" });
const granularityList = computed<string[]>(() => {
  if (findGranularity.value[1]) {
    return ["MONTH", "WEEK", "DAY"];
  } else {
    return ["MONTH", "WEEK"];
  }
});
const granularityCurrentItem = ref<"MONTH" | "WEEK">("MONTH");
const currentItemSearchValue = ref<SelectedSearchingItem>(null);
const valueReset = ref<{ BRAND_SHORT_NAME: string; BRAND_NAME: string } | {}>(
  {},
);
const valueResetChannels = ref<{ CHANNEL_ID: string; NAME: string } | {}>({});

const date = ref<[string, string]>([
  DateTime.now().minus({ month: 3 }).toUTC().toISO(),
  DateTime.now().toUTC().toISO(),
] as [string, string]);

const currentPeriod = ref<Period>({
  since: DateTime.now().minus({ month: 3 }).toUTC().toISODate() as string,
  until: DateTime.now().toUTC().toISODate() as string,
});

const previousDateKPI = ref<[string, string]>([
  DateTime.now().minus({ days: 180 }).toUTC().toISO(),
  DateTime.now().minus({ days: 91 }).toUTC().toISO(),
] as [string, string]);
const kpiPreviousPeriod = ref<Period>({
  since: DateTime.now().minus({ days: 180 }).toUTC().toISODate() as string,
  until: DateTime.now().minus({ days: 91 }).toUTC().toISODate() as string,
});

const presetRanges = ref<PresetRange[]>([
  {
    label: "Last Week",
    value: [
      DateTime.now().startOf("week").minus({ weeks: 1, days: 1 }),
      DateTime.now().startOf("week").minus({ days: 2 }),
    ],
  },
  {
    label: "Month to Date",
    value: [DateTime.now().startOf("month"), DateTime.now()],
  },
  {
    label: "Year to Date",
    value: [DateTime.now().startOf("year"), DateTime.now()],
  },

  {
    label: "Last 90 Days",
    value: [DateTime.now().minus({ days: 90 }), DateTime.now()],
  },
  {
    label: "Last 180 Days",
    value: [DateTime.now().minus({ days: 180 }), DateTime.now()],
  },
  {
    label: "Last 365 Days",
    value: [DateTime.now().minus({ days: 365 }), DateTime.now()],
  },
]);
const presetRangesForComparison = ref<PresetRange[]>([
  {
    label: "2 Weeks Ago",
    value: [
      DateTime.now().startOf("week").minus({ weeks: 2, days: 1 }).toUTC(),
      DateTime.now().startOf("week").minus({ weeks: 1, days: 2 }).toUTC(),
    ],
  },
  {
    label: "Prior Month to Date",
    value: [
      DateTime.now().startOf("month").minus({ months: 1 }).toUTC(),
      DateTime.now().minus({ month: 1 }).toUTC(),
    ],
  },
  {
    label: "Prior Year to Date",
    value: [
      DateTime.now().startOf("year").minus({ years: 1 }).toUTC(),
      DateTime.now().minus({ year: 1 }).toUTC(),
    ],
  },
]);

const searchBySellerValue = ref("");
const usersSearch = ref("");

const findGranularity = computed(() => {
  const finded = (route.meta.filters as string[])
    ?.filter((el) => el.split(":").includes("granularity"))
    .flatMap((el) => {
      if (el.split(":").includes("granularity")) {
        return el.split(":");
      }
      return [];
    });
  return finded || [];
});

const findSearch = computed(() => {
  const finded = (route.meta.filters as string[])
    ?.filter((el) => el.split(":").includes("search"))
    .flatMap((el) => {
      if (el.split(":").includes("search")) {
        return el.split(":");
      }
      return [];
    });
  return finded || [];
});

const findStatus = computed(() => {
  const finded = (route.meta.filters as string[])
    ?.filter((el) => el.split(":").includes("productStatus"))
    .flatMap((el) => {
      if (el.split(":").includes("productStatus")) {
        return el.split(":");
      }
      return [];
    });
  return finded || [];
});

const handleDate = async (modelData: [string, string], reset?: boolean) => {
  date.value = modelData;
  filterStore.updateDate(date.value);
  const startDate: Date = new Date(modelData[0]);
  const endDate: Date = new Date(modelData[1]);

  currentPeriod.value = {
    since: DateTime.fromJSDate(new Date(startDate)).toISODate() as string,
    until: DateTime.fromJSDate(new Date(endDate)).toISODate() as string,
  };

  filterStore.updatePicker(currentPeriod.value),
    filterStore.updateParsedDateToMonths(
      parseDateUtils(
        currentPeriod.value.since,
        currentPeriod.value.until,
        granularityCurrentItem.value,
        formattedDatesArray,
      ),
    );

  if (!reset) {
    router.push({
      query: {
        ...route.query,
        main_since: currentPeriod.value.since,
        main_until: currentPeriod.value.until,
      },
    });
  }
};

const handlePoPDate = async (modelData: [string, string], reset?: boolean) => {
  previousDateKPI.value = modelData;
  const startDate: Date = new Date(modelData[0]);
  const endDate: Date = new Date(modelData[1]);

  kpiPreviousPeriod.value = {
    since: DateTime.fromJSDate(new Date(startDate)).toISODate() as string,
    until: DateTime.fromJSDate(new Date(endDate)).toISODate() as string,
  };

  filterStore.updateKPIsPickerInStore(kpiPreviousPeriod.value);

  if (!reset) {
    router.push({
      query: {
        ...route.query,
        comparison_since: kpiPreviousPeriod.value.since,
        comparison_until: kpiPreviousPeriod.value.until,
      },
    });
  }
};

const updateByProduct = async function (item: UpdateByProduct) {
  productSearch.value = [];
  filterStore.updateProductSearchInStore(productSearch.value);
  filterStore.updateProductAfterSearch(item);
  valueReset.value = {
    BRAND_SHORT_NAME: filterStore.product?.BRAND_SHORT_NAME,
    BRAND_NAME: filterStore.product?.BRAND_NAME,
  };
  currentItemSearchValue.value = filterStore.product as SelectedSearchingItem;
  filterStore.updateCurrentSelectedProductInStore(currentItemSearchValue.value);
  productSearch.value.push(filterStore.product?.PRODUCT_ID as number);
  filterStore.updateProductSearchInStore(productSearch.value);

  router.push({
    query: {
      ...route.query,
      product: currentItemSearchValue.value?.ASIN,
    },
  });
};

const debouncedSearchFilter = debounce(
  (value: string) => searchFilter(value),
  500,
);

const searchFilter = (search: string) => {
  filterStore.updateSearchInStore(search);
  if (findSearch.value[0] && findSearch.value[1] !== "custom") {
    store.searchProduct({ search: filterStore.search });
  }
};

const datepickerBottomText = computed<string>(() => {
  const date = DateTime.now().minus({ day: 2 }).toFormat("MMMM dd, yyyy");

  return `Latest Available Date ${date}`;
});

const updateBrands = (value: string[]) => {
  filterStore.updatedBrandBySelect(true);
  filterStore.updateBrandsInStore(value.includes("") ? [] : value);
  selectedBrand.value = value.includes("") ? [] : value;

  if (value.length === 0) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { brands, ...rest } = route.query;
    router.push({ query: { ...rest } });
    return;
  }

  const selectedBrandsToString = selectedBrand.value.join(",");
  if (selectedBrandsToString.length) {
    router.push({ query: { ...route.query, brands: selectedBrandsToString } });
  }
};

const updateChannels = function (value: number[]) {
  filterStore.updatedChannelBySelect(true);
  filterStore.updateChannelsInStore(value.includes(0) ? [] : value);
  selectedChannel.value = value.includes(0) ? [] : value;

  if (value.length === 0) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { channels, ...rest } = route.query;
    router.push({ query: { ...rest } });
    return;
  }

  const selectedChannelsToString = selectedChannel.value.join(",");
  if (selectedChannelsToString.length) {
    router.push({
      query: { ...route.query, channels: selectedChannelsToString },
    });
  }
};

const resetAllFilters = async () => {
  await router.push({ query: {} });
  searchBySellerValue.value = "";
  usersSearch.value = "";
  granularityCurrentItem.value = "MONTH";
  blockUpdateGranylarity.value = true;
  productStatus.value = { name: "All", value: "" };
  amzStatus.value = { name: "All", value: "" };
  selectedProduct.value = null;
  updateProductStatus({ name: "All", value: "" });
  updateAmzStatus({ name: "All", value: "" });
  filterStore.updateGranularityInStore("MONTH");
  filterStore.updateCurrentSelectedProductInStore(null);
  searchFilter("");
  const preselectedBrandGroup: ProductGroup | undefined =
    store.brandGroups?.find(
      (el) =>
        !!el.BRANDS?.find(
          (item) =>
            item.BRAND_SHORT_NAME === store.brands[0]["BRAND_SHORT_NAME"],
        ),
    );
  if (preselectedBrandGroup) {
    const brands = preselectedBrandGroup.BRANDS?.map(
      (item) => item.BRAND_SHORT_NAME,
    );
    // updateBrands(brands);
    updateBrands([]);
  } else {
    updateBrands([]);
    // updateBrands([store.brands[0]['BRAND_SHORT_NAME']]);
  }

  // updateChannels([store.channels[0]['CHANNEL_ID']]);
  updateChannels([]);

  handleDate(
    [
      DateTime.now().minus({ month: 3 }).toUTC().toISO(),
      DateTime.now().toUTC().toISO(),
    ] as [string, string],
    true,
  );

  currentPeriod.value = {
    since: DateTime.now()?.minus({ month: 3 }).toUTC().toISODate() as string,
    until: DateTime.now().toUTC().toISODate() as string,
  };

  previousDateKPI.value = [
    DateTime.now().minus({ days: 180 }).toUTC().toISODate(),
    DateTime.now().minus({ days: 91 }).toUTC().toISODate(),
  ] as [string, string];

  kpiPreviousPeriod.value = {
    since: DateTime.now().minus({ days: 180 }).toUTC().toISODate() as string,
    until: DateTime.now().minus({ days: 91 }).toUTC().toISODate() as string,
  };

  filterStore.updateParsedDateToMonths(
    parseDateUtils(
      currentPeriod.value.since,
      currentPeriod.value.until,
      granularityCurrentItem.value,
      formattedDatesArray,
    ),
  );

  const findedDefaultBrand = store.brands[0];
  valueReset.value = {
    BRAND_SHORT_NAME: findedDefaultBrand?.["BRAND_SHORT_NAME"],
    BRAND_NAME: findedDefaultBrand?.["BRAND_NAME"],
  };

  const findedDefaultChannel = store.channels[0];
  valueResetChannels.value = {
    CHANNEL_ID: findedDefaultChannel?.["CHANNEL_ID"],
    NAME: findedDefaultChannel?.["NAME"],
  };

  // updateGranularity('MONTH');

  filterStore.updateProductSearchInStore([]);
  filterStore.updateCurrentSelectedProductInStore(null);
};

const formatDate = (date: string) => {
  if (!date) return;
  return DateTime.fromJSDate(new Date(date)).toFormat("MM/dd/yyyy");
};

const updateProductStatus = (value: {
  name: string;
  value: "active" | "inactive" | "";
}) => {
  filterStore.updateProductStatus(value.value);
};

const updateAmzStatus = (value: {
  name: string;
  value: "active" | "inactive" | "";
}) => {
  filterStore.updateAmzStatus(value.value);
};

watch(granularityCurrentItem, () => {
  filterStore.updateGranularityInStore(
    granularityCurrentItem.value as "MONTH" | "WEEK",
  );
  if (blockUpdateGranylarity.value) {
    blockUpdateGranylarity.value = false;
    return;
  }
  router.push({
    query: { ...route.query, granularity: granularityCurrentItem.value },
  });
});

watch(
  () => filterStore.currentSelectedProduct,
  () => {
    if (!filterStore.currentSelectedProduct?.BRAND_NAME) {
      selectedBrand.value = filterStore.brands[0];
      const foundOption = store.brands.filter((el) => {
        return filterStore.brands.find((item) => {
          if (item === el.BRAND_SHORT_NAME) {
            return el;
          }
        });
      });
      selectedProduct.value = filterStore.currentSelectedProduct;

      if (!foundOption.length) {
        const findedDefaultBrand = store.brands[0];
        updateBrands([]);

        valueReset.value = {
          BRAND_SHORT_NAME: findedDefaultBrand?.["BRAND_SHORT_NAME"],
          BRAND_NAME: findedDefaultBrand?.["BRAND_NAME"],
        };
        return;
      } else {
        valueReset.value = {
          BRAND_SHORT_NAME: foundOption[0]?.["BRAND_SHORT_NAME"],
          BRAND_NAME: foundOption[0]?.["BRAND_NAME"],
        };
      }
    }
  },
);

watch(usersSearch, () => {
  filterStore.updateSearchInStore(usersSearch.value);
});

watch(searchBySellerValue, () => {
  filterStore.updateSearchBySeller(searchBySellerValue.value);
});

onBeforeMount(() => {
  const brands =
    (route.query?.brands as string)?.split(",")[0] === ""
      ? []
      : (route.query?.brands as string)?.split(",");
  const channels =
    (route.query?.channels as string)?.split(",").map((el) => +el)[0] === 0
      ? []
      : (route.query?.channels as string)?.split(",").map((el) => +el);
  if (filterStore.updatedBrandBySelectValue) {
    filterStore.updatedBrandBySelect(false);
    return;
  }
  if (filterStore.updatedChannelBySelectValue) {
    filterStore.updatedChannelBySelect(false);
    return;
  }
  if (route.query?.granularity) {
    filterStore.updateGranularityInStore(
      route.query?.granularity as "MONTH" | "WEEK",
    );
    granularityCurrentItem.value = route.query?.granularity as "MONTH" | "WEEK";
  }

  filterStore.updateBrandsInStore(brands || []);
  filterStore.updateChannelsInStore(channels || []);
});

onMounted(async () => {
  filterStore.getRefreshDate();
  isLoading.value = true;
  filterStore.updateLoading(isLoading.value);
  filterStore.updateParsedDateToMonths(
    parseDateUtils(
      currentPeriod.value.since,
      currentPeriod.value.until,
      granularityCurrentItem.value,
      formattedDatesArray,
    ),
  );
  if (!localStorage.getItem("accessToken")) return;

  try {
    await Promise.all([
      store.fetchBrands(),
      store.fetchChannels(),
      store.fetchBrandGroups(),
    ]);
    if (route.query?.product) {
      try {
        await store.searchProduct({ search: route.query?.product as string });
        filterStore.updateCurrentSelectedProductInStore(
          store.searchProductResult[0] as any,
        );
        filterStore.updateSearchInStore(store.searchProductResult[0]?.ASIN);
        filterStore.updateProductSearchInStore([
          store.searchProductResult[0]?.PRODUCT_ID,
        ]);
      } catch (e) {
        console.log(e);
      }
    }

    const preselectedBrandGroup: ProductGroup | undefined =
      store.brandGroups?.find(
        (el) =>
          !!el.BRANDS?.find(
            (item) =>
              item.BRAND_SHORT_NAME === store.brands[0]["BRAND_SHORT_NAME"],
          ),
      );
    if (preselectedBrandGroup) {
      // const brands = preselectedBrandGroup.BRANDS?.map((item) => item.BRAND_SHORT_NAME);
      // updateBrands(brands);
      // updateBrands([]);
    } else {
      // updateBrands([store.brands[0]['BRAND_SHORT_NAME']]);
      // updateBrands([]);
    }

    // updateChannels([store.channels[0]['CHANNEL_ID']]);
    // updateChannels([]);
    if (!route.query?.main_since && !route.query?.main_until) {
      filterStore.updatePicker(currentPeriod.value);
    } else {
      filterStore.updatePicker({
        since: route.query?.main_since as string,
        until: route.query?.main_until as string,
      } as Period);

      date.value = [
        route.query?.main_since as string,
        route.query?.main_until as string,
      ] as [string, string];
    }

    if (!route.query?.comparison_since && !route.query?.comparison_until) {
      filterStore.updateKPIsPickerInStore(kpiPreviousPeriod.value);
    } else {
      filterStore.updateKPIsPickerInStore({
        since: route.query?.comparison_since as string,
        until: route.query?.comparison_until as string,
      } as Period);

      previousDateKPI.value = [
        route.query?.comparison_since as string,
        route.query?.comparison_until as string,
      ] as [string, string];
    }
    selectedProduct.value = filterStore.currentSelectedProduct;

    isLoading.value = false;
    filterStore.updateLoading(isLoading.value);
  } catch {
    isLoading.value = false;
    filterStore.updateLoading(isLoading.value);
  }
});
</script>

<template>
  <div
    class="side_bar_wrapper transition-all duration-500 overflow-y-auto rounded-tl-xl border-2 dark:border-cardBg"
    :class="{
      opened: filterStore.isOpenSidebar,
      closed: !filterStore.isOpenSidebar,
      'pt-[100px]': props.isFixed,
    }"
  >
    <div class="flex justify-between px-5 pt-3">
      <div class="flex gap-1 items-center">
        <feather-icon type="filter" size="20px" />
        <span class="text-mainText font-medium text-[24px]"> Filters </span>
      </div>
      <feather-icon
        type="x"
        width="25"
        class="cursor-pointer stroke-mainText"
        @click="filterStore.updateFilterIsOpenSidebar(false)"
      />
    </div>

    <div v-if="!isLoading" class="flex flex-col items-start gap-4 p-4">
      <div class="flex flex-col w-full">
        <div v-if="!isLoading" class="flex flex-col items-start gap-4 w-full">
          <article
            class="flex flex-col gap-1 w-full"
            v-if="(route.meta.filters as string[])?.includes('brands')"
          >
            <span class="text-secondaryText font-medium text-[16px]"
              >Brand</span
            >
            <BrandsDropDown
              @valueUpdate="updateBrands"
              :defaultValue="filterStore.brands.toString()"
              class="w-full"
            />
          </article>

          <article
            class="flex flex-col gap-1 w-full"
            v-if="(route.meta.filters as string[])?.includes('channels')"
          >
            <span class="text-secondaryText font-medium text-[16px]"
              >Channels</span
            >
            <ChannelsDropDown
              :options="[{ CHANNEL_ID: 0, NAME: 'All' }, ...store.channels]"
              @valueUpdate="updateChannels"
              :defaultValue="filterStore.channels?.toString()"
              :value-reset="valueResetChannels"
            />
          </article>
          <article class="flex flex-col gap-1 w-full" v-if="findSearch[0]">
            <span class="text-secondaryText font-medium text-[16px]"
              >{{ findSearch[2] ?? "Products" }}
            </span>
            <CommonMultiSelect
              v-if="findSearch[2] !== 'Users' && findSearch[2] !== 'Campaigns'"
              v-model="selectedProduct"
              label="PRODUCT_NAME"
              class="w-full"
              track-by="PRODUCT_NAME"
              @search-change="debouncedSearchFilter"
              :loading="store.isSearchProductResultLoading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :placeholder="
                findSearch[3]?.replace(/-/g, ' ') ??
                'Search by Name, ASIN or SKU'
              "
              :options="
                filterStore.simpleSearch ? undefined : store.searchProductResult
              "
              @select="updateByProduct"
            >
              <template #option="{ option }">
                <div class="max-w-[300px] text-wrap text-mainText">
                  <span class="text-mainText">{{
                    `${option.SKU}${option.ASIN ? ` - ${option.ASIN}` : ""}`
                  }}</span>
                  :
                  <span class="text-secondary-Text">{{
                    option.PRODUCT_NAME
                  }}</span>
                </div>
              </template>
            </CommonMultiSelect>
            <BaseSimpleSearch
              v-else
              v-model="usersSearch"
              simpleSearch
              :placeholder="
                findSearch[3]?.replace(/-/g, ' ') ?? 'Search by Name or Email'
              "
              class="w-full"
              debounceProp
            />
          </article>

          <article
            class="flex flex-col gap-1 w-full"
            v-if="(route.meta.filters as string[])?.includes('searchBySeller')"
          >
            <span class="text-secondaryText font-medium text-[16px]"
              >Seller
            </span>
            <BaseSimpleSearch
              v-model="searchBySellerValue"
              simpleSearch
              placeholder="Search by name or AmazonID"
              class="w-full"
              debounceProp
            />
          </article>

          <div class="flex flex-col gap-3 w-full" v-if="findStatus[0]">
            <span class="text-secondaryText font-medium text-[16px]">
              {{ findStatus[1]?.replace(/-/g, " ") ?? "Product Status" }}</span
            >

            <CommonMultiSelect
              v-model="productStatus"
              label="name"
              :options="[
                { value: '', name: 'All' },
                { value: 'active', name: 'Active' },
                { value: 'inactive', name: 'Inactive' },
              ]"
              @select="updateProductStatus"
            >
            </CommonMultiSelect>
          </div>
          <div
            class="flex flex-col gap-3 w-full"
            v-if="(route.meta.filters as string[])?.includes('amzStatus')"
          >
            <span class="text-secondaryText font-medium text-[16px]">
              Listing Status</span
            >
            <common-multi-select
              v-model="amzStatus"
              label="name"
              :options="[
                { value: '', name: 'All' },
                { value: 'active', name: 'Active' },
                { value: 'inactive', name: 'Inactive' },
              ]"
              @select="updateAmzStatus"
            />
          </div>

          <div
            class="flex flex-col w-full gap-1"
            v-if="(route.meta.filters as string[])?.includes('picker')"
          >
            <span class="text-secondaryText font-medium text-[16px]"
              >Main Date Range</span
            >
            <CommonDatePicker
              :model-value="date"
              range
              :preset-dates="presetRanges"
              week-start="0"
              placeholder="Choose the period"
              :enable-time-picker="false"
              @update:model-value="handleDate"
            >
              <template #yearly="{ label }">
                <span>{{ label }}</span>
              </template>
              <template #action-preview="{ value }">
                <article class="flex flex-col">
                  <span v-if="value">
                    Selected period:
                    {{ formatDate(value[0]) }}
                    -
                    {{ formatDate(value[1]) }}
                  </span>

                  <span>{{ datepickerBottomText }}</span>
                </article>
              </template>
            </CommonDatePicker>
          </div>
          <div
            class="flex flex-col w-full gap-1"
            v-if="(route.meta.filters as string[])?.includes('kpiPicker')"
          >
            <span class="text-secondaryText font-medium text-[16px]"
              >Comparison Date Range</span
            >
            <CommonDatePicker
              range
              :preset-dates="presetRangesForComparison"
              :model-value="previousDateKPI"
              week-start="0"
              placeholder="Choose the KPI period"
              :enable-time-picker="false"
              @update:model-value="handlePoPDate"
            >
              <template #action-preview="{ value }">
                <article class="flex flex-col">
                  <span v-if="value">
                    Selected period:
                    {{ formatDate(value[0]) }}
                    -
                    {{ formatDate(value[1]) }}
                  </span>

                  <span>{{ datepickerBottomText }}</span>
                </article>
              </template>
            </CommonDatePicker>
          </div>

          <div
            v-if="(route.meta.filters as string[])?.includes('hideOutOfStock')"
            class="w-full py-3 flex gap-3 items-center"
          >
            <BaseSwitch
              v-model:checked="filterStore.hideOutStock"
              label="Hide out of stock"
            />
          </div>

          <div class="w-full flex gap-2 justify-between items-center mt-2">
            <CommonGranularity
              v-if="findGranularity[0]"
              :list="granularityList"
              :current-item="granularityCurrentItem"
              v-model:model-value="granularityCurrentItem"
            />
          </div>
          <article
            class="transition-all duration-500 text-mainText border-2 border-red-500 w-full flex justify-center items-center p-3 cursor-pointer rounded-lg hover:shadow-lg hover:bg-red-500 hover:text-white h-[40px]"
            @click="resetAllFilters"
          >
            <span class="block"> Reset All</span>
          </article>
          <article class="w-full">
            <span class="text-mainText"> Latest Data Refresh: </span>
            <span class="text-secondaryText">
              {{
                DateTime.fromJSDate(new Date(filterStore.refreshDate))
                  .toUTC()
                  .toFormat("MM/dd/yyyy HH:mm")
              }}
              UTC
            </span>
          </article>

          <SavingFilters />
        </div>

        <div v-else class="flex flex-col w-full h-[calc(100vh-150px)]">
          <div v-for="item in 3" :key="item" class="flex flex-col gap-2 p-5">
            <BaseSkeleton height="25px" width="30%" />
            <BaseSkeleton height="40px" width="100%" />
          </div>
          <div class="flex flex-col gap-2 p-5">
            <BaseSkeleton height="25px" width="45%" />
            <BaseSkeleton height="40px" width="100%" />
            <BaseSkeleton height="40px" width="70%" />
          </div>
          <div class="flex px-5 gap-3 items-center">
            <div class="flex w-full">
              <BaseSkeleton height="40px" width="30%" />
              <BaseSkeleton height="40px" width="30%" />
            </div>
            <div class="w-full">
              <BaseSkeleton height="40px" width="100%" />
            </div>
          </div>
          <div class="p-5">
            <BaseSkeleton height="40px" width="100%" />
          </div>
        </div>
      </div>
    </div>

    <div v-else class="flex flex-col w-full h-[calc(100vh-150px)]">
      <div v-for="item in 3" :key="item" class="flex flex-col gap-2 p-5">
        <BaseSkeleton height="25px" width="30%" />
        <BaseSkeleton height="40px" width="100%" />
      </div>
      <div class="flex flex-col gap-2 p-5">
        <BaseSkeleton height="25px" width="45%" />
        <BaseSkeleton height="40px" width="100%" />
        <BaseSkeleton height="40px" width="70%" />
      </div>
      <div class="flex px-5 gap-3 items-center">
        <div class="flex w-full">
          <BaseSkeleton height="40px" width="30%" />
          <BaseSkeleton height="40px" width="30%" />
        </div>
        <div class="w-full">
          <BaseSkeleton height="40px" width="100%" />
        </div>
      </div>
      <div class="p-5">
        <BaseSkeleton height="40px" width="100%" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.side_bar_wrapper {
  height: calc(100vh);
  @apply fixed right-0 top-0 bg-mainBg z-[11] w-[80vw] md:w-[40vw] lg:w-[35vw] 2xl:w-[30vw]  shadow-lg;
}

.opened {
  @apply right-0;
}

.closed {
  @apply right-[-90vw];
}
</style>
