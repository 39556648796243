<script setup lang="ts">
import { useRoute } from "vue-router";
import { ref, computed, reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, minLength } from "@vuelidate/validators";

import { useStore } from "@/store/main";
import { registerTeleport } from "@/utils/modal";
import { useFiltersStore } from "@/store/filters";
import { createUserFilter } from "@/types/filters";
import {
  renameChannels,
  capitalizeFirstLetterOfEachWord,
  formatOldFormatOfDateToAmericanFormat,
} from "@/utils/formatters";

import BaseButton from "@/components/common/CommonButton.vue";
import BaseModal from "@/components/common/CommonModal.vue";
import BaseCheckbox from "@/components/common/CommonCheckbox.vue";
import BaseFormInput from "@/components/common/FormInput.vue";

const store = useStore();
const route = useRoute();
const createNewMarkModal = registerTeleport("createNewMarkModal");
const filterStore = useFiltersStore();

const newMarkForm = reactive({
  name: "",
  timeCheckbox: true,
});
const rules = computed(() => {
  return {
    name: {
      required: required,
      minLength: minLength(3),
      maxLength: maxLength(20),
    },
  };
});
const v$ = useVuelidate(rules, newMarkForm);

const selectedIndex = ref(0);

const deleteUserFilterApi = (id: string) => {
  filterStore.deleteUserFilter(id);
};

const sortedFilter = computed(() => {
  return filterStore.userFilters.slice().sort((a: any, b: any) => {
    if (a.favorite && !b.favorite) return -1;
    if (!a.favorite && b.favorite) return 1;
    if (a.date > b.date) return -1;
    if (a.date < b.date) return 1;
    return 0;
  });
});

const transformFilterArray = (filterArray: createUserFilter[]) => {
  const result: Record<string, string> = {};

  filterArray.forEach(({ key, value }: any) => {
    if (key.includes("_since") || key.includes("_until")) {
      const [sinceKey, untilKey] = key.split("_since");
      const [sinceValue, untilValue] = value.split(" - ");

      result[`${sinceKey}_since`] = sinceValue;
      result[`${sinceKey}_until`] = untilValue;
    } else {
      result[key] = value;
    }
  });

  return result;
};

const formattingQuery = (query: string) => {
  const queries = query?.split("&");
  const formattedQueries = queries?.reduce((accumulator: any, currentQuery) => {
    const [key, value] = currentQuery.split("=");
    if (key === "main_since" || key === "comparison_since") {
      const untilKey = key.replace("_since", "_until");
      const untilQuery = queries.find((query) => query.startsWith(untilKey));
      if (untilQuery) {
        const [untilKey, untilValue] = untilQuery.split("=");
        accumulator.push({ key: `${key}`, value: `${value} - ${untilValue}` });
      }
    } else if (key === "main_until" || key === "comparison_until") {
      // Do nothing for these keys, they will be handled with since keys
    } else {
      accumulator.push({ key, value });
    }
    return accumulator;
  }, []);
  formattedQueries?.sort((a: any, b: any) => {
    if (a.key === "product") return -1;
    if (b.key === "product") return 1;
    return 0;
  });
  return formattedQueries;
};

const getQueriesFromFilter = (link: any) => {
  const query = link?.split("?")[1];
  return formattingQuery(query);
};

const toggleFavorite = (filter: any, index: number) => {
  filter.favorite = !filter.favorite;
  selectedIndex.value = index;
  filterStore.setFavoriteFilter(filter.id, filter.favorite);
};

const createNewMark = () => {
  v$.value.$validate();
  if (v$.value.$error) return;
  filterStore.createUserFilter(
    {
      name: newMarkForm.name,
      favorite: false,
      link: routeQueries.value,
    },
    () => createNewMarkModal.close(),
  );
};

const currentSelectedFilter = computed(() =>
  formattingQuery(route.fullPath?.split("?")[1])?.filter((el: any) => {
    if (
      !newMarkForm.timeCheckbox &&
      !el.key.includes("since") &&
      !el.key.includes("until")
    ) {
      return el;
    }
    if (newMarkForm.timeCheckbox) {
      return el;
    }
  }),
);

const isShowCheckbox = computed(() => {
  return formattingQuery(route.fullPath?.split("?")[1])?.some(
    (el: Record<string, string>) => {
      if (
        el.key.includes("main_since") ||
        el.key.includes("comparison_since")
      ) {
        return true;
      }
    },
  );
});

const windowHref = computed(() => `${window.location.origin}${route.path}?`);

const routeQueries = computed(() => {
  const formattedQueries = transformFilterArray(currentSelectedFilter.value);
  const queryParams = Object.entries(formattedQueries)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}=${value.join(",")}`;
      }
      return `${key}=${value}`;
    })
    .join("&");
  return `${queryParams}`;
});
</script>

<template>
  <section class="flex flex-col gap-3 w-full">
    <div>
      <BaseButton
        text="Save current filter"
        @click="() => createNewMarkModal.open()"
      >
        <template #iconLeft>
          <feather-icon type="book-open" size="20" />
        </template>
      </BaseButton>
    </div>
    <div class="flex flex-col text-mainText">
      <transition-group name="filter" tag="div" class="flex flex-col gap-2">
        <div
          v-for="(filter, index) in sortedFilter"
          :key="filter.id"
          class="flex p-3 gap-1 justify-between bg-cardBg shadow-lg dark:shadow-slate-900/50 rounded-lg"
          :class="{ 'filter-move': index === selectedIndex }"
        >
          <div class="h-full">
            <feather-icon
              type="bookmark"
              size="20"
              @click="toggleFavorite(filter, index)"
              class="cursor-pointer hover:text-[gold] transition-all duration-300"
              :class="{ 'text-[gold]': filter.favorite }"
            />
          </div>

          <div class="flex flex-col w-full">
            <a
              class="text-primary font-normal text-[16px]"
              :href="windowHref + filter.link"
            >
              {{ filter.name }}
            </a>
            <span
              v-for="(item, index) in getQueriesFromFilter('?' + filter.link)"
              :key="index"
              class="text-secondaryText flex gap-2 items-center"
            >
              <span
                class="text-primary font-light"
                v-if="item.key === 'main_since'"
              >
                <feather-icon
                  type="calendar"
                  size="20"
                  class="cursor-pointer"
                  v-tooltip="capitalizeFirstLetterOfEachWord(item.key)"
                />
              </span>
              <span
                class="text-primary font-light"
                v-else-if="item.key === 'comparison_since'"
              >
                <feather-icon
                  type="trending-up"
                  size="20"
                  class="cursor-pointer"
                  v-tooltip="capitalizeFirstLetterOfEachWord(item.key)"
                />
              </span>
              <span
                class="text-primary font-light"
                v-else-if="item.key === 'granularity'"
              >
                <feather-icon
                  type="layout"
                  size="20"
                  class="cursor-pointer"
                  v-tooltip="capitalizeFirstLetterOfEachWord(item.key)"
                />
              </span>
              <div v-else-if="item.key === 'channels'">
                <span class="font-normal"> Channels: </span>
                <span class="font-light">
                  {{
                    item.value
                      .split(",")
                      .map((el: string | number) =>
                        renameChannels(store.channels, +el),
                      )
                      .join(", ")
                  }}
                </span>
              </div>
              <span class="font-normal capitalize" v-else>
                {{ item.key }}:
              </span>
              <span
                class="font-light"
                v-if="
                  item.key !== 'channels' &&
                  item.key !== 'main_since' &&
                  item.key !== 'comparison_since'
                "
              >
                {{ item.value }}
              </span>
              <span
                v-if="
                  item.key === 'main_since' || item.key === 'comparison_since'
                "
                class="font-light"
              >
                {{
                  formatOldFormatOfDateToAmericanFormat(
                    item.value.split(" - ")[0],
                  )
                }}
                -
                {{
                  formatOldFormatOfDateToAmericanFormat(
                    item.value.split(" - ")[1],
                  )
                }}
              </span>
            </span>
          </div>
          <div>
            <feather-icon
              type="trash"
              size="20"
              class="cursor-pointer hover:text-negative transition-all duration-300"
              @click="deleteUserFilterApi(filter.id)"
              v-if="
                !filterStore.isUpdateUserFilters &&
                !filterStore.isDeleteUserFilters
              "
            />
            <feather-icon
              v-else
              type="trash"
              size="20"
              class="text-secondaryText cursor-not-allowed"
            />
          </div>
        </div>
      </transition-group>
    </div>

    <BaseModal
      button-text="Save Filter"
      title="Select Name for Filter"
      should-be-modal-closed-on-submit
      :teleport="createNewMarkModal"
      max-width="500px"
    >
      <template #buttons>
        <div>
          <BaseButton
            text="Save Filter"
            @click="createNewMark"
            :disabled="!currentSelectedFilter"
          />
        </div>
      </template>

      <div class="flex flex-col gap-3">
        <BaseFormInput
          placeholder="Filter Name"
          v-model="newMarkForm.name"
          :validation="v$.name"
        />
        <div>
          <span
            v-for="(item, index) in currentSelectedFilter"
            :key="index"
            class="text-secondaryText flex gap-2 items-center font-light"
          >
            <span
              class="flex items-center gap-2"
              v-if="item.key === 'main_since'"
            >
              <feather-icon
                type="calendar"
                size="20"
                class="cursor-pointer text-primary"
                v-tooltip="capitalizeFirstLetterOfEachWord(item.key)"
              />
              {{
                formatOldFormatOfDateToAmericanFormat(
                  item.value.split(" - ")[0],
                )
              }}
              -
              {{
                formatOldFormatOfDateToAmericanFormat(
                  item.value.split(" - ")[1],
                )
              }}
            </span>
            <span
              class="flex items-center gap-2"
              v-else-if="item.key === 'comparison_since'"
            >
              <feather-icon
                type="trending-up"
                size="20"
                class="cursor-pointer text-primary"
                v-tooltip="capitalizeFirstLetterOfEachWord(item.key)"
              />
              {{
                formatOldFormatOfDateToAmericanFormat(
                  item.value.split(" - ")[0],
                )
              }}
              -
              {{
                formatOldFormatOfDateToAmericanFormat(
                  item.value.split(" - ")[1],
                )
              }}
            </span>
            <span
              class="text-primary font-light"
              v-else-if="item.key === 'granularity'"
            >
              <feather-icon
                type="layout"
                size="20"
                class="cursor-pointer"
                v-tooltip="capitalizeFirstLetterOfEachWord(item.key)"
              />
            </span>
            <div v-else-if="item.key === 'channels'">
              <span class="font-normal"> Channels: </span>
              <span class="font-normal">
                {{
                  item.value
                    .split(",")
                    .map((el: string | number) =>
                      renameChannels(store.channels, +el),
                    )
                    .join(", ")
                }}
              </span>
            </div>
            <span class="font-normal capitalize" v-else> {{ item.key }}: </span>
            <span
              class="font-light"
              v-if="
                item.key !== 'channels' &&
                item.key !== 'main_since' &&
                item.key !== 'comparison_since'
              "
            >
              {{ item.value }}
            </span>
          </span>
        </div>
        <div v-if="isShowCheckbox">
          <BaseCheckbox
            v-model="newMarkForm.timeCheckbox"
            label="Save selected time on filter"
          />
        </div>
      </div>
    </BaseModal>
  </section>
</template>

<style scoped>
.filter-move {
  transition: transform 0.5s ease;
}
</style>
