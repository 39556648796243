import { Permissions } from "@/types/permissions";
import type { UsersTableData } from "@/types/users";

export const checkInsightful = (user: UsersTableData) => {
  if (
    user?.permissions?.includes(Permissions.SALES_REPORT) &&
    user?.permissions?.includes(Permissions.ADS_SUMMARY) &&
    user?.permissions?.includes(Permissions.REVIEW_REPORT) &&
    user?.permissions?.includes(Permissions.BRAND_PROTECTION)
  ) {
    return true;
  }
};

export const generateColor = (index: number) => {
  const red = Math.sin((index + 1) * 0.3) * 127 + 128;
  const green = Math.sin((index + 2) * 0.3) * 127 + 128;
  const blue = Math.sin((index + 3) * 0.3) * 127 + 128;
  return `rgb(${red}, ${green}, ${blue})`;
};

export const capitalizeFirstLetterOfEachWord = (string: string) => {
  return string
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const calculatePercentages = (a: number, b: number) => {
  const total = a + b;
  const percentA = (a / total) * 100;
  const percentB = (b / total) * 100;
  return [percentA, percentB];
};

export const calculatePercentage = (
  value: number,
  maxValue: number,
): number => {
  if (maxValue === 0) return 0;
  return (value / maxValue) * 100;
};

export const formattingReason = (reason: string) => {
  if (reason === "Awaiting_Product_Return") {
    return "Awaiting Product Return / Missing Reason";
  } else return capitalizeFirstLetterOfEachWord(reason);
};

export const checkToPositive = (value: number) => {
  if (Math.sign(value) === 0) return "0";
  return Math.sign(value) === 1;
};
