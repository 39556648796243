import axios from "@/utils/axios";
import type { PaginatedWrapper, requestParams } from "@/types/requests.ts";
import {
  IWarehouseChart,
  IWarehouseKpis,
  IWarehouseKpisTotal,
  IWarehouseTable,
} from "@/types/warehouse";
import {
  IReceivingChart,
  IReceivingKpis,
  IReceivingKpisTotal,
  IReceivingSecondChart,
  IReceivingTable,
} from "@/types/warehouse/receiving.ts";
import {
  IPreppingKpis,
  IPreppingKpisTotal,
  IPreppingTable,
  IPreppingUserPerformanceTable,
  IUnitsByBrandTable,
} from "@/types/warehouse/prepping.ts";
import { ICycleChart, ICycleTable } from "@/types/warehouse/cycleTime.ts";

export const getOutboundKpisApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IWarehouseKpis>(
    "/warehouse/outbound/outbound-kpi",
    params,
    options,
  );
};

export const getOutboundKpiTotalApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IWarehouseKpisTotal>(
    "/warehouse/outbound/outbound-kpi-totals",
    params,
    options,
  );
};

export const getOutboundChartApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IWarehouseChart[]>(
    "/warehouse/outbound/outbound-chart",
    params,
    options,
  );
};

export const getOutboundTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<IWarehouseTable[]>>(
    "/warehouse/outbound/outbound-table",
    params,
    options,
  );
};

export const getReceivingKpisApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IReceivingKpis>(
    "/warehouse/receiving/receiving-kpi",
    params,
    options,
  );
};

export const getReceivingKpiTotalApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IReceivingKpisTotal>(
    "/warehouse/receiving/receiving-kpi-totals",
    params,
    options,
  );
};

export const getReceivingKpiChartApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IReceivingChart[]>(
    "/warehouse/receiving/receiving-chart-1",
    params,
    options,
  );
};

export const getReceivingSecondChartApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IReceivingSecondChart[]>(
    "/warehouse/receiving/receiving-chart-2",
    params,
    options,
  );
};

export const getReceivingTimeTableByBrandApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<IReceivingTable[]>>(
    "/warehouse/receiving/receiving-table-by-brand",
    params,
    options,
  );
};

export const getReceivingTimeTableByProductApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<IReceivingTable[]>>(
    "/warehouse/receiving/receiving-table-by-product",
    params,
    options,
  );
};

export const getPreppingKpisApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IPreppingKpis>(
    "/warehouse/prepping/prepping-kpi",
    params,
    options,
  );
};

export const getPreppingKpiTotalApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IPreppingKpisTotal>(
    "/warehouse/prepping/prepping-kpi-totals",
    params,
    options,
  );
};

export const getPreppingUserPerformanceTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<IPreppingUserPerformanceTable[]>>(
    "/warehouse/prepping/prepping-table-user-performance",
    params,
    options,
  );
};

export const getPreppingByUserTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<IPreppingTable[]>>(
    "/warehouse/prepping/prepping-table-by-user",
    params,
    options,
  );
};

export const getPreppingByProductTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<IPreppingTable[]>>(
    "/warehouse/prepping/prepping-table-by-product",
    params,
    options,
  );
};

export const getPreppingChartApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<any[]>(
    "/warehouse/prepping/prepping-chart",
    params,
    options,
  );
};

export const getPreppingUnitsByBrandTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IUnitsByBrandTable[]>(
    "/warehouse/prepping/prepping-table-units-by-brand",
    params,
    options,
  );
};

export const getCycleByProductTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<ICycleTable[]>>(
    "/warehouse/cycle-time/cycle-time-table-by-product",
    params,
    options,
  );
};

export const getCycleByBrandTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<ICycleTable[]>>(
    "/warehouse/cycle-time/cycle-time-table-by-brand",
    params,
    options,
  );
};

export const getCycleChartApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ICycleChart[]>(
    "/warehouse/cycle-time/cycle-time-chart",
    params,
    options,
  );
};
